import React, { useEffect, memo } from "react";
import "aos/dist/aos.css";
import sounds from "../../../images/soundsSignal.png";
import img2 from "../../../images/VectorOne.png";
import img3 from "../../../images/VectorTow.png";
import img4 from "../../../images/mice.png";
import img5 from "../../../images/piano.png";
import img6 from "../../../images/podcastar.png";
import img7 from "../../../images/podcasten.png";
import img8 from "../../../images/VectorTree.png";
import img9 from "../../../images/shapear.png";
import img10 from "../../../images/shapeen.png";
import img11 from "../../../images/shapeOnear.png";
import img12 from "../../../images/shapeOneen.png";
import img13 from "../../../images/VectorFor.png";
import AOS from "aos";
import DOMPurify from "dompurify";

import { useSelector } from "react-redux";

const FirstSection = memo(({ firstSectionData }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const section_description = DOMPurify.sanitize(
    firstSectionData.section_description
  );

  const section_description_en = DOMPurify.sanitize(
    firstSectionData.section_description_en
  );

  const lng = useSelector((state) => state.lang.value);
  return (
    <div className="container mx-auto relative mt-[20px]">
      <div
        className={`flex flex-col lg:flex-row items-center ${
          lng === "en" ? "lg:text-left" : "lg:text-right"
        }  text-center mt-[50px] lg:mt-[0px] mb-[80px] gap-[40px] lg:gap-[0px] justify-center lg:justify-between min-h-[60vh] lg:min-h-[80vh] px-[20px] lg:px-0`}
      >
        {lng === "en" ? (
          <div className="hidden sm:block absolute left-0 top-[4%]">
            <img src={img10} alt="" className="w-[60%] md:w-[80%]" />
          </div>
        ) : (
          <div className="hidden sm:block absolute right-0 top-[4%]">
            <img src={img9} alt="" className="w-[60%] md:w-[80%]" />
          </div>
        )}
        {lng === "en" ? (
          <div className="hidden sm:block absolute w-[90px] md:w-[80%] top-[2.6%] left-0">
            <img src={img12} alt="" />
          </div>
        ) : (
          <div className="hidden sm:block absolute w-[90px] md:w-[80%] top-[2.6%] right-0">
            <img src={img11} alt="" />
          </div>
        )}
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="content flex-1 flex flex-col gap-[20px] sm:gap-[35px]"
        >
          <h1 className="text-purple text-f-title font-bold">
            {lng === "ar"
              ? firstSectionData.section_name
              : firstSectionData.section_name_en}
          </h1>
          <p className="relative text-gray lg:max-w-[78%] text-f-desc leading-7 mx-auto lg:mx-0">
            <p className="text-purpleLight"></p>
            {lng === "ar" ? (
              <div
                dir="rtl"
                dangerouslySetInnerHTML={{ __html: section_description }}
              />
            ) : (
              <div
                dir="ltr"
                dangerouslySetInnerHTML={{ __html: section_description_en }}
              />
            )}
          </p>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="3000"
          className="mx-[35px] img mt-[15px] relative w-full h-[300px] sm:w-[410px] sm:h-[400px] lg:w-[500px] lg:h-[500px]"
        >
          <img
            src={img2}
            alt=""
            className="absolute top-0 rotation w-full h-full"
          />
          <img
            src={img3}
            alt=""
            className="absolute top-0 rotation z-10 w-full h-full"
          />
          <img
            src={img8}
            alt=""
            className="absolute top-0 z-10 w-full h-full"
          />
          <img
            src={img13}
            alt=""
            className="absolute top-0 z-10 w-full h-full"
          />
          <div className="absolute top-0 right-[10%] lg:right-[38px] sh w-[15%] sm:w-[60px] flex items-center justify-center z-40 bg-white rounded-full">
            <img src={img4} alt="" />
          </div>
          <div className="absolute bottom-[10px] right-[10%] lg:right-[38px] w-[15%] sm:w-[60px] sm:h-[60px] sh flex items-center justify-center z-40 bg-white rounded-full">
            <img src={img5} alt="" />
          </div>
          {lng === "en" ? (
            <div className="absolute top-[100px] sm:top-[190px] left-[-50px] sm:left-[-85px] w-[100px] h-[30px] sm:w-[175px] flex items-center justify-center z-40 bg-white rounded-full">
              <img src={img7} alt="" className="sh rounded-lg" />
            </div>
          ) : (
            <div className="absolute top-[100px] sm:top-[190px] left-[-50px] sm:left-[-85px] w-[100px] h-[30px] sm:w-[175px] flex items-center justify-center z-40 bg-white rounded-full">
              <img src={img6} alt="" className="sh rounded-lg" />
            </div>
          )}
          <img
            src={firstSectionData.section_logo}
            className="absolute top-0 left-0 w-full h-full object-cover"
            alt="image-firstsection"
          />
        </div>
      </div>
      <div className=" absolute w-screen left-0 bottom-[-35%] mb-[50px] sm:mb-0">
        <img
          src={sounds}
          className="w-full bg-cover object-cover"
          alt="Sound Signal"
        />
      </div>
    </div>
  );
});

export default FirstSection;
