import React, { memo } from "react";
import Title from "../../../components/Title";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AboutUsCard from "../../../components/AboutUsCard";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

const AboutUsSection = memo(({ servicesSectionData }) => {
  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();

  const restServices = servicesSectionData.records.slice(
    2,
    servicesSectionData.records.length
  );
  return (
    <div className=" container mx-auto">
      <div className="max-w-screen-xl mt-5">
        <div className="flex items-center justify-between mb-[35px]">
          <Title
            title={
              lng === "ar"
                ? servicesSectionData.section_name
                : servicesSectionData.section_name_en
            }
          />
          <Link
            to={"/our-services"}
            className="text-viewAll w-[112px] sm:w-auto underline text-purple"
          >
            {t("View all")}
          </Link>
        </div>
        <p className="mb-12 text-center sm:text-start text-lg">
          {t("AboutParagraph")}
        </p>

        {/* Responsive Cards Layout */}
        <div className="flex flex-col gap-[20px]">
          <div className="flex gap-[20px] flex-wrap sm:flex-nowrap">
            <AboutUsCard
              titleAr={servicesSectionData.records[0].title_ar}
              titleEn={servicesSectionData.records[0].title_en}
              paragraphAr={DOMPurify.sanitize(
                servicesSectionData.records[0].description_ar
              )}
              paragraphEn={DOMPurify.sanitize(
                servicesSectionData.records[0].description_en
              )}
              icon={servicesSectionData.records[0].icon_url}
            />

            <AboutUsCard
              titleAr={servicesSectionData.records[1].title_ar}
              titleEn={servicesSectionData.records[1].title_en}
              paragraphAr={DOMPurify.sanitize(
                servicesSectionData.records[1].description_ar
              )}
              paragraphEn={DOMPurify.sanitize(
                servicesSectionData.records[1].description_en
              )}
              icon={servicesSectionData.records[1].icon_url}
            />
          </div>
          <div className="flex gap-[20px] flex-wrap md:flex-nowrap">
            {restServices.map((service) => (
              <AboutUsCard
                key={service.id}
                titleAr={service.title_ar}
                titleEn={service.title_en}
                paragraphAr={DOMPurify.sanitize(service.description_ar)}
                paragraphEn={DOMPurify.sanitize(service.description_en)}
                icon={service.icon_url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default AboutUsSection;
