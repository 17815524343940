import React from "react";
import { useSelector } from "react-redux";
import styles from "./ourClient.module.css";

const OurClients = (props) => {
  const lng = useSelector((state) => state.lang.value);
  return (
    <div class={styles.container}>
      <div class={styles.card}>
        <div
          class={`${lng === "en" ? "left-[1.5%]" : "right-[1.5%]"} ${
            styles.image
          }`}
        >
          <img className={styles.img} alt="" src={props.image} />
        </div>
        <div class={styles.content}>
          <h3 className="">{lng === "ar" ? props.titleAr : props.titleEn}</h3>
          <p
            dir={lng === "ar" ? "rtl" : "ltr"}
            className={`h-[166px] overflow-hidden`}
            dangerouslySetInnerHTML={{
              __html: lng === "ar" ? props.descAr : props.descEn,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OurClients;
