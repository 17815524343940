import React, { useEffect } from "react";
import styles from "./MySwiper.module.css"; // Import the CSS module
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AOS from "aos";
import { useSelector } from "react-redux";

const FirstSection = ({ data }) => {
  const lng = useSelector((state) => state.lang.value);
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <h1 className="text-xl font-medium">
        {lng === "ar"
          ? `نعرض هنا صور المعدات المستخدمة في خدمة ${data.title_ar}`
          : `Here are pictures of the equipment used in ${data.title_en} service.`}
      </h1>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="mt-[20px]"
      >
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: `.${styles.swiper_pagination}`, clickable: true }}
          navigation={{
            nextEl: `.${styles.swiper_button_next}`,
            prevEl: `.${styles.swiper_button_prev}`,
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className={styles.swiper_container}
        >
          {data?.media?.map((img) => (
            <SwiperSlide className={styles.swiper_slide}>
              <img
                src={img.original_url}
                alt="slide_image"
              />
            </SwiperSlide>
          ))}

          <div className={styles.slider_controler}>
            <div className={styles.swiper_button_prev}>
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className={styles.swiper_button_next}>
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className={styles.swiper_pagination}></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default FirstSection;
