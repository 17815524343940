import React, { useEffect, useState } from "react";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Keyboard } from "swiper/modules";
import { useSelector } from "react-redux";

const Slide = ({ children }) => {
  const lng = useSelector((state) => state.lang.value);
  const [ar, setAr] = useState(true);
  useEffect(() => {
    const isRTL = () => {
      if (lng === "en") {
        setAr(false);
      } else {
        setAr(true);
      }
    };

    isRTL();
  }, [lng]);
  return (
    <Swiper
      rtl={ar}
      slidesPerView={1}
      spaceBetween={5}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      breakpoints={{
        440: {
          slidesPerView: 1.2,
          spaceBetween: 5,
        },
        571: {
          slidesPerView: 1.6,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 1.6,
          spaceBetween: 5,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        1024: {
          slidesPerView: 2.5,
          spaceBetween: 5,
        },
        1280: {
          slidesPerView: 3.2,
          spaceBetween: 5,
        },
        1535: {
          slidesPerView: 3.9,
          spaceBetween: 5,
        },
      }}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Pagination, Keyboard]}
      className={`mySwiper flex items-start justify-start`}
    >
      <div
        className={`swiper-button-next swiper-button-next-${
          lng === "ar" ? "rtl" : "ltr"
        }`}
      ></div>
      <div
        className={`swiper-button-prev swiper-button-prev-${
          lng === "ar" ? "rtl" : "ltr"
        }`}
      ></div>
      {children}
    </Swiper>
  );
};

export default Slide;
