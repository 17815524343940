import Title from "../../../components/Title";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SwiperSlide } from "swiper/react";
import Slide from "../../../components/Slide";
import AOS from "aos";
import "aos/dist/aos.css";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";

const ThirdSection = memo(({ thirdSectionData }) => {
  const lng = useSelector((state) => state.lang.value);
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();
  return (
    <div className=" container mx-auto">
      <div
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className=" flex flex-col gap-[40px] mb-[50px]"
      >
        <div className=" flex items-center justify-between">
          <Title
            title={
              lng === "ar"
                ? thirdSectionData.section_name
                : thirdSectionData.section_name_en
            }
          />
          <Link
            to={"/videos"}
            className="text-viewAll w-[112px] sm:w-auto underline text-purple"
          >
            {t("View all")}
          </Link>
        </div>
        <div
          dir={lng === "en" ? "ltr" : "rtl"}
          className="w-full flex items-start justify-start h-[500px]"
        >
          <Slide>
            {thirdSectionData.records.map((item) => (
              <SwiperSlide>
                <Card
                  id={item.id}
                  image={item.image}
                  link={`/videos/${item.id}`}
                  type="video"
                  title={lng === "en" ? item.titleEn : item.titleAr}
                  button={lng === "en" ? "Watch the video" : "شاهد الفيديو"}
                  desc={lng === "en" ? item.descEn : item.descAr}
                  VideoSrc={item.videoSrc}
                />
              </SwiperSlide>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
});

export default ThirdSection;
