import React, { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "../../../components/Title";
import Slide from "../../../components/Slide";
import { SwiperSlide } from "swiper/react";
import BlogCard from "../../../components/BlogCard";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

const ForthSection = memo(({ forthSectionData }) => {
  const lng = useSelector((state) => state.lang.value);
  useEffect(() => {
    AOS.init();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-image flex flex-col justify-center items-center">
      <div className=" container mx-auto">
        <div
          data-aos="fade-up-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className="py-10 flex flex-col gap-10"
        >
          <div className="flex items-center justify-between">
            <Title
              title={
                lng === "ar"
                  ? forthSectionData.section_name
                  : forthSectionData.section_name_en
              }
            />
            <Link
              to={"/articles"}
              className="text-viewAll w-[112px] sm:w-auto underline text-purple mt-4 sm:mt-0"
            >
              {t("View all")}
            </Link>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-8 sm:gap-12">
            <div
              dir={lng === "en" ? "ltr" : "rtl"}
              className="w-full h-[550px]"
            >
              <Slide>
                {forthSectionData.records.map((item, i) => (
                  <SwiperSlide key={item.id}>
                    <BlogCard
                      link={`/articles/${item.id}`}
                      dateAr={item.published_at}
                      dateEn={item.published_at_en}
                      image={item.image_url}
                      titleAr={item.title}
                      titleEn={item.title_en}
                      descAr={item.description}
                      descEn={item.description_en}
                    />
                  </SwiperSlide>
                ))}
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ForthSection;
