import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "1",
  VideoSrc: "",
  VideoTitle: "",
  clicked: "no",
};
const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.value = action.payload;
    },
    setVideoSrc: (state, action) => {
      state.VideoSrc = action.payload;
    },
    setVideoTitle: (state, action) => {
      state.VideoTitle = action.payload;
    },
    setCliked: (state, action) => {
      state.clicked = action.payload;
    },
  },
});
export const { setId, setCliked, setVideoSrc, setVideoTitle } =
  videoSlice.actions;
export default videoSlice.reducer;
