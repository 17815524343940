import { configureStore } from "@reduxjs/toolkit";
import langReducer from "../features/langSlice";
import soundReducer from "../features/soundSlice";
import videoReducer from "../features/videoSlice";

const store = configureStore({
  reducer: {
    lang: langReducer,
    sound: soundReducer,
    video: videoReducer,
  },
});

export default store;
