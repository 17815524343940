import React, { useEffect } from "react";
import icon from "../../../images/servicesIcon.png";
import { CiCreditCard1 } from "react-icons/ci";
import ThirdSection from "./ThirdSection";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import AOS from "aos";

const SecondSection = ({ data }) => {
  const section_descriptionAr = DOMPurify.sanitize(data.description_ar);
  const section_descriptionEn = DOMPurify.sanitize(data.description_en);
  useEffect(() => {
    AOS.init();
  }, []);
  const lng = useSelector((state) => state.lang.value);

  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row justify-between gap-[60px]">
      <div
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <div className="flex items-center border-b-[1px] border-gray gap-[12px]">
          <div className="py-3 relative">
            <div className=" absolute w-full bg-purpleLight h-[9px] rounded-lg bottom-[-4px]"></div>
            <img src={icon} className="w-[50px] h-[50px]" alt="" />
          </div>
          <h1 className="text-2xl text-purple font-medium">
            {t("Service Description")}
          </h1>
        </div>
        <div className="text-md text-gray mt-[20px]">
          <div
            className="text-gray"
            dir={lng === "ar" ? "rtl" : "ltr"}
            dangerouslySetInnerHTML={{
              __html:
                lng === "ar" ? section_descriptionAr : section_descriptionEn,
            }}
          />
        </div>
        <div className="mt-[50px]">
          <ThirdSection>
            <div
              className="text-gray"
              dir={lng === "ar" ? "rtl" : "ltr"}
              dangerouslySetInnerHTML={{
                __html: lng === "ar" ? data.steps_ar : data.steps_en,
              }}
            />
          </ThirdSection>
        </div>
      </div>

    </div>
  );
};

export default SecondSection;
