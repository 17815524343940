import React, { useState, useRef, useEffect } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBarsProgress } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import { english, arabic } from "../features/langSlice";
import i18n from "../translation/i18n";
import arabicFlag from "../images/arabic-flag.png";
import englishFlag from "../images/engish-flag.png";
import { MdLanguage } from "react-icons/md";
import apiClient from "../api/baseUrl/axiosConfig";
import { IoMdArrowDropdown } from "react-icons/io";
const Header = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [toggleMore, setToggleMore] = useState(false);

  const menuRef = useRef(null);

  const [selcted, setSelected] = useState(null);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setToggle(false); // Close the menu
      setToggleOne(false); // Close the menu
    }
  };

  useEffect(() => {
    const getPages = async () => {
      try {
        const response = await apiClient.get("/pagesslug");
        setAllData(response.data);
        setMainData(response.data.slice(0, 5));
        setData(response.data.slice(5, response.data.length));
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    getPages();
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const togglee = (i) => {
    if (selcted === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const [toggle, setToggle] = useState(false);
  const [toggleOne, setToggleOne] = useState(false);
  const lng = useSelector((state) => state.lang.value);
  const dispatch = useDispatch();

  return (
    <div
      ref={menuRef}
      className=" relative container z-50 mx-auto py-[40px] flex"
    >
      <Link
        to={"/"}
        className={`sh sm:w-[20%] bg-purpleLight ${
          lng === "en"
            ? "sm:rounded-l-full rounded-l-2xl"
            : "sm:rounded-r-full rounded-r-2xl"
        } px-[3%] py-[1%] h-[90px] flex items-center justify-center`}
      >
        <img src={logo} className="w-[80%] sm:w-[100%]" alt="logo-image" />
      </Link>
      <div
        className={`w-full flex items-center h-[90px] px-[4%] bg-purple ${
          lng === "en"
            ? "sm:rounded-r-full rounded-r-2xl"
            : "sm:rounded-l-full rounded-l-2xl"
        }`}
      >
        <div className="hidden  w-full h-full lg:flex items-center text-lg gap-[20px] xl:gap-[40px]">
          {mainData?.map((item, i) => (
            <Link
              key={item.id}
              onClick={() => togglee(i)}
              to={item.slug}
              className={`hover:text-white transtion-[1.2s] ${
                selcted === i ? "text-white" : "text-whiteOne"
              }`}
            >
              {lng === "ar" ? item.title : item.title_en}
            </Link>
          ))}

          {allData.length > 5 ? (
            <div className={` relative cursor-pointer`}>
              <h1
                onClick={() => setToggleMore((prev) => !prev)}
                className="hover:text-white text-whiteOne flex items-center gap-[10px] transtion-[1.2s]"
              >
                {lng === "ar" ? "المزيد" : "more"} <IoMdArrowDropdown />
              </h1>
              {toggleMore === true ? (
                <div className="absolute w-[250px] flex flex-col gap-[10px] top-[50px] bg-purpleLight rounded-lg p-[15px]">
                  <div
                    className={` absolute bg-purpleLight top-[-10px] ${
                      lng === "ar" ? "lg:right-[10px]" : "lg:right-[10px]"
                    } w-[20px] h-[20px] rotate-45 left-[13px]`}
                  ></div>

                  {data?.map((item, i) => (
                    <Link
                      key={item.id}
                      onClick={() => {
                        setToggleMore(false);
                        togglee(i);
                      }}
                      to={item.slug}
                      className={`hover:text-white transtion-[1.2s] ${
                        selcted === i ? "text-white" : "text-whiteOne"
                      }`}
                    >
                      {lng === "ar" ? item.title : item.title_en}
                    </Link>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className=" cursor-pointer relative h-full justify-center w-full lg:w-auto lg:justify-end flex items-center text-white text-xl">
          <h1
            className=" flex items-center text-sm sm:text-xl gap-[8px]"
            onClick={() => setToggleOne(!toggleOne)}
          >
            <MdLanguage />
            {t("Language")}
          </h1>
          {toggleOne && (
            <div
              ref={menuRef}
              className="absolute w-[150px] flex flex-col gap-[10px] top-[80%] bg-purpleLight rounded-lg p-[15px]"
            >
              <div
                className={` absolute bg-purpleLight top-[-10px] ${
                  lng === "ar" ? "lg:left-[10px]" : "lg:left-[120px]"
                } w-[20px] h-[20px] rotate-45 left-[45%]`}
              ></div>
              <div
                onClick={() => {
                  setToggleOne(!toggleOne);
                  i18n.changeLanguage("en");
                  dispatch(english());
                }}
                className="flex text-sm sm:text-xl items-center gap-[7px]"
              >
                <img
                  src={englishFlag}
                  className="w-[20px] sm:w-[30px]"
                  alt=""
                />
                <h1>{t("English")}</h1>
              </div>
              <div
                onClick={() => {
                  setToggleOne(!toggleOne);
                  i18n.changeLanguage("ar");
                  dispatch(arabic());
                }}
                className="flex text-sm sm:text-xl items-center gap-[7px]"
              >
                <img src={arabicFlag} className="w-[20px] sm:w-[30px]" alt="" />
                <h1>{t("Arabic")}</h1>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => setToggle(!toggle)}
          className={` relative lg:hidden w-full flex-1 h-full justify-end flex items-center text-white cursor-pointer`}
        >
          <FaBarsProgress className="sm:text-2xl text-xl mx-2" />
          {/*  */}

          <div
            className={`flex fixed w-full sm:w-[40%] bg-purpleLight pb-[30px] h-full transition-[1s] duration-500 z-[150] ${
              toggle === true
                ? lng === "en"
                  ? "right-0"
                  : "left-0"
                : lng === "en"
                ? "right-[-100%]"
                : "left-[-100%]"
            } top-0 flex-col gap-[20px] items-center py-[20px]`}
          >
            <div className="text-3xl text-bage w-full px-[20px] flex items-center justify-between">
              <h1
                className="w-[40px] h-[40px] text-center pb-[9px] flex items-center justify-center rounded-full border-[2px] border-purple"
                onClick={() => setToggle(!toggle)}
              >
                x
              </h1>
              <img
                onClick={() => setToggle(!toggle)}
                src={logo}
                className="w-[25%]"
                alt="logo-image"
              />
            </div>
            {allData.map((item, i) => (
              <Link
                onClick={() => {
                  setToggle(false);
                  togglee(i);
                }}
                to={item.slug}
                className={`
                      border-b-[1px] text-white border-gray w-full flex items-center justify-center pb-[18px]
                      
                    `}
              >
                {lng === "ar" ? item.title : item.title_en}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
