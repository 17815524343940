import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCliked } from "../features/videoSlice";

const OverLayVideo = () => {
  const dispatch = useDispatch();
  const vid = useSelector(function (state) {
    return state.video;
  });

  const lng = useSelector(function (state) {
    return state.lang.value;
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <div className="fixed inset-0 w-screen duration-500 transition-all h-screen video-bg z-[100] flex items-center justify-center">
      <div
        onClick={() => dispatch(setCliked("no"))}
        className={`text-white bg-purpleLight p-[20px] rounded-xl  cursor-pointer absolute ${
          lng === "en" ? "top-[3%] left-[3%]" : "top-[3%] right-[3%]"
        } text-center flex items-center gap-[20px]`}
      >
        <p className="font-semibold text-xl sm:text-xl md:text-2xl video-bg w-[40px] h-[40px] rounded-full border-2 border-purpleLight">
          x
        </p>
        <p className="text-md sm:text-xl font-semibold">
          {lng === "en" ? "Close video" : "إغلاق الفيديو"}
        </p>
      </div>

      <div className="w-full flex flex-col items-center justify-center gap-[10px]">
        <h1 className="text-start text-whiteOne sm:w-[60%] font-medium text-lg sm:text-xl md:text-2xl">
          {lng === "en"
            ? `Video: ${vid.VideoTitle}`
            : `فيديو: ${vid.VideoTitle}`}
        </h1>
        <video
          className=" rounded-xl w-[90%] sm:w-[60%] border-[2px] border-purpleLight "
          ref={videoRef}
          controls
          src={vid.VideoSrc}
          onClick={handlePlayPause} // Play/Pause on click
        />
      </div>
    </div>
  );
};

export default OverLayVideo;
