import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  value: Cookies.get("i18next") || "ar",
};
const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    english: (state) => {
      window.location.reload();
      setTimeout(() => {
        state.value = Cookies.get("i18next");
      }, 600);
    },
    arabic: (state) => {
      window.location.reload();
      setTimeout(() => {
        state.value = Cookies.get("i18next");
      }, 600);
    },
  },
});
export const { english, arabic } = langSlice.actions;
export default langSlice.reducer;
