import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

const AllServicesCard = (props) => {
  const section_descriptionAr = DOMPurify.sanitize(props.descAr);
  const section_descriptionEn = DOMPurify.sanitize(props.descEn);

  const lng = useSelector((state) => state.lang.value);
  return (
    <div className="p-[20px] flex flex-col gap-[20px] border-b-[1px] border-gray">
      <div className="w-[92px] h-[93px] rounded-full">
        <img src={props.icon} className="w-full h-full rounded-full" alt="" />
      </div>
      <div>
        <h1 className="text-xl font-medium text-purpleLight">
          {lng === "en" ? props.titleEn : props.titleAr}
        </h1>
      </div>
      <div className="max-h-[100px] overflow-hidden">
        <div
          className="text-gray"
          dir={lng === "ar" ? "rtl" : "ltr"}
          dangerouslySetInnerHTML={{
            __html:
              lng === "ar" ? section_descriptionAr : section_descriptionEn,
          }}
        />
      </div>
      <Link
        to={`/our-services/${props.linkId}`}
        className="w-full sm:w-[65%] py-[15px] text-center rounded-3xl text-white service-card"
      >
        {lng === "en" ? "View more" : "مشاهدة المزيد"}
      </Link>
    </div>
  );
};

export default AllServicesCard;
