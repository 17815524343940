import Slide from "../../../components/Slide";
import { SwiperSlide } from "swiper/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import AOS from "aos";

const ImagesSection = ({ data }) => {
  const lng = useSelector((state) => state.lang.value);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      data-aos="fade-down-right"
      data-aos-easing="linear"
      data-aos-duration="1000"
      className="w-full h-[450px]"
      dir={lng === "en" ? "ltr" : "rtl"}
    >
      <Slide>
        {data.records.map((image) => (
          <SwiperSlide key={image.id}>
            <div class="max-w-sm rounded-lg overflow-hidden sh">
              <div className="h-[200px] relative">
                <img
                  class="w-full h-full object-cover"
                  src={image.file_url}
                  alt="Sunset"
                />
              </div>
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  {lng === "en" ? image.title_en : image.title}
                </div>
                <div class="text-gray-700 text-base max-h-[100px] overflow-hidden">
                  <div
                    dir={lng === "ar" ? "rtl" : "ltr"}
                    dangerouslySetInnerHTML={{
                      __html:
                        lng === "en" ? image.description_en : image.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Slide>
    </div>
  );
};

export default ImagesSection;
