import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { useTranslation } from "react-i18next";
import FirstSection from "./components/FirstSection";
import { Link } from "react-router-dom";
import VideosSection from "./components/VideosSection";
import VoiceSection from "./components/VoiceSection";
import ImagesSection from "./components/ImagesSection";
import LibTitle from "./components/LibTitle";
import img from "../../images/bgOne.png";
import { getGeneralSection } from "../../api/generalSection/getGeneralSection";
import { useSelector } from "react-redux";

const LibraryPage = () => {
  const lng = useSelector((state) => state.lang.value);
  const [loading, setLoading] = useState(true);
  const [libraryData, setLibraryData] = useState([]);
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const fetchLibraryData = async () => {
      try {
        const response = await getGeneralSection("library");
        setLibraryData(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Failed to load library data. Please try again."); // Set error message
        console.error("Error fetching library data:", err); // Log the error for debugging
      }
    };
    fetchLibraryData();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="overflow-hidden">
      {loading ? (
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <div className="loader"></div>
        </div>
      ) : error ? ( // Display error message if there's an error
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <p className="text-red-500">{error}</p>
        </div>
      ) : (
        <div className="relative">
          <div className="my-[20px] flex-col flex gap-[0px]">
            <div className="container mx-auto">
              <Title
                title={
                  lng === "ar"
                    ? libraryData[0]?.section_name
                    : libraryData[0]?.section_name_en
                }
              />
              <FirstSection data={libraryData[0]} />
              <div>
                <div className="flex mt-[150px] items-center justify-between w-full">
                  <LibTitle
                    title={
                      lng === "ar"
                        ? libraryData[1]?.section_name
                        : libraryData[1]?.section_name_en
                    }
                  />
                  <Link
                    to={"/videos"}
                    className="underline text-viewAll text-purple"
                  >
                    {t("View all")}
                  </Link>
                </div>
                <VideosSection data={libraryData[1]} />
              </div>
            </div>

            <div className="relative">
              {/* Background image with fixed positioning */}
              <div className="absolute inset-0 w-full h-full top-[15%]">
                <img src={img} alt="" className="w-full h-full" />
              </div>
              <div className="relative z-40 container mx-auto">
                <div className="flex mt-[150px] mb-[40px] items-center justify-between w-full">
                  <LibTitle
                    title={
                      lng === "ar"
                        ? libraryData[2]?.section_name
                        : libraryData[2]?.section_name_en
                    }
                  />
                </div>
                <VoiceSection data={libraryData[2]} />
              </div>
            </div>

            <div className="mb-[120px] container mx-auto relative z-30">
              <div className="flex mt-[150px] mb-[40px] items-center justify-between w-full">
                <LibTitle
                  title={
                    lng === "ar"
                      ? libraryData[3]?.section_name
                      : libraryData[3]?.section_name_en
                  }
                />
              </div>
              <ImagesSection data={libraryData[3]} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LibraryPage;
