import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import ContactUs from "./pages/contact/ContactUs";
import LibraryPage from "./pages/Library/LibraryPage";
import ServicesPages from "./pages/Services/ServicesPages";
import AllServices from "./pages/Services/AllServices";
import About from "./pages/about/About";
import Blogs from "./pages/Blogs/Blogs";
import Faqs from "./pages/FAQ/Faqs";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import VideoDetails from "./pages/VideoDetails/VideoDetails";
import Videos from "./pages/videos/Videos";
import NotFoundPage from "./pages/404/NotFoundPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/home-page" element={<HomePage />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/library" element={<LibraryPage />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/articles" element={<Blogs />} />
      <Route path="/articles/:id" element={<BlogDetails />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/videos/:id" element={<VideoDetails />} />
      <Route path="/faqspage" element={<Faqs />} />
      <Route path="/our-services/:id" element={<ServicesPages />} />
      <Route path="/our-services" element={<AllServices />} />
      <Route path="*" element={<NotFoundPage />} /> {/* 404 route */}
    </Routes>
  );
};

export default AppRoutes;
