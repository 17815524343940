import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BlogCard = (props) => {
  const lng = useSelector((state) => state.lang.value);
  const [shouldSlideClass, setShouldSlideClass] = useState("");

  const textShouldSlideTrue = () => {
    if (
      props.titleAr?.length > (lng === "ar" ? 70 : 50) ||
      props.titleEn?.length > (lng === "ar" ? 70 : 50)
    ) {
      setShouldSlideClass(lng === "ar" ? "sliding-text" : "sliding-text-en");
    }
  };

  const textShouldSlideFalse = () => {
    setShouldSlideClass("");
  };

  return (
    <div class="max-w-sm bg-white border sh border-purpleLight rounded-lg shadow">
      <img
        class="rounded-t-lg h-[260px] w-full object-cover rounded-br-[90px]"
        src={props.image}
        alt=""
      />
      <div class="p-5">
        <div className="flex items-center justify-between">
          <div class="mb-2 sliding-text-container text-purpleLight text-lg  font-bold tracking-tight h-[35px]">
            <h5
              onMouseEnter={textShouldSlideTrue}
              onMouseLeave={textShouldSlideFalse}
              className={shouldSlideClass}
            >
              {lng === "ar" ? props.titleAr : props.titleEn}
            </h5>
          </div>
        </div>
        <div
          dir={lng === "ar" ? "rtl" : "ltr"}
          className="mb-3 font-normal text-gray w-[300px] h-[100px] overflow-hidden"
          dangerouslySetInnerHTML={{
            __html: lng === "en" ? props.descEn : props.descAr,
          }}
        />
        <Link
          to={props.link}
          class="inline-flex items-center underline text-sm font-medium text-center text-orange"
        >
          {lng === "ar" ? "المقالة كاملة" : "Full Blog"}
          <svg
            class="rtl:rotate-180 w-3 h-3 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </Link>
        {/*<div className="flex items-center text-sm mt-[10px] gap-[20px]">*/}
        {/*  <div className="flex items-center gap-[5px]">*/}
        {/*    <FaEye className="text-orange" />*/}
        {/*    <h1>450</h1>*/}
        {/*    <h1>{lng === "ar" ? "مشاهدة" : "View"}</h1>*/}
        {/*  </div>*/}
        {/*  <div className="flex items-center gap-[5px]">*/}
        {/*    <FaRegHeart className="text-orange" />*/}
        {/*    <h1>70</h1>*/}
        {/*    <h1>{lng === "ar" ? "لايك" : "Like"}</h1>*/}
        {/*  </div>*/}
        {/*  <h2 className="text-gray text-xs">*/}
        {/*    {lng === "ar" ? props.dateAr : props.dateEn}*/}
        {/*  </h2>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default BlogCard;
