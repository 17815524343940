import React, { useEffect, useState } from "react";
import ImageCard from "./ImageCard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import apiClient from "../../../api/baseUrl/axiosConfig";
import { useParams } from "react-router-dom";

const ForthSection = () => {
  const { t } = useTranslation();
  const lng = useSelector((state) => state.lang.value);
  const { id } = useParams();
  const [previousWorks, setPreviousWorks] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await apiClient.get(`/services/${id}`);
        setPreviousWorks(response.data.previous_works[0].media);
      } catch (err) {}
    };
    getData();
  }, [id]);
  return (
    <div>
      <section class="text-gray-600 body-font">
        <h1
          className={`text-xl text-purple border-purple py-[7px] ${
            lng === "en"
              ? "border-l-[6px] pl-[10px]"
              : "border-r-[6px] pr-[10px]"
          }  rounded-md font-medium`}
        >
          {t("Related previous works")}
        </h1>
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap gap-[25px] -mb-10 -mt-4">
            {previousWorks.length > 0 ? (
              previousWorks.map((item) => (
                <div key={item.id}>
                  <ImageCard
                    fileType={item.mime_type}
                    src={item.original_url}
                    title={item.name}
                    title_en={item.name_en}
                  />
                </div>
              ))
            ) : (
              <div>
                {lng === "ar"
                  ? "لا يوجد اعمال سابقة في الوقت الحالي"
                  : "There are no previous works at this time."}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForthSection;
