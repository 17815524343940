import React, { useState, useEffect, useMemo } from "react";
import Title from "../../components/Title";
import { useTranslation } from "react-i18next";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import image from "../../images/faq.png";
import imagebg from "../../images/bgtransparent.png";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import apiClient from "../../api/baseUrl/axiosConfig";
import Loader from "../../components/Loader"; // Import loader component

const Faqs = () => {
  const [loading, setLoading] = useState(true);
  const [faqSectionData, setFaqSectionData] = useState({});
  const [faqData, setFaqData] = useState([]);
  const [selected, setSelected] = useState(null);

  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const [sectionResponse, faqsResponse] = await Promise.all([
          apiClient.get("/pages/faqs"),
          apiClient.get("/faqs"),
        ]);

        setFaqSectionData(sectionResponse.data);
        setFaqData(faqsResponse.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching FAQs:", err);
      }
    };

    fetchData();
  }, []);

  const toggle = (i) => {
    setSelected((prevSelected) => (prevSelected === i ? null : i));
  };

  const faqTitle = useMemo(
    () => (
      <Title
        title={lng === "ar" ? faqSectionData.title : faqSectionData.title_en}
      />
    ),
    [faqSectionData, lng]
  );

  return (
    <div className="overflow-hidden">
      {loading ? (
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="container mx-auto my-[20px] flex flex-col gap-[50px]">
          {faqTitle}

          <div
            data-aos="zoom-in"
            data-aos-easing="linear"
            data-aos-duration="1000"
            className="w-full relative text-center h-[200px] lg:h-[300px] 2xl:h-[400px]"
          >
            <img src={imagebg} alt="" className="w-full h-full absolute" />
            <div className="center z-30 text-center flex flex-col gap-[10px] lg:gap-[30px]">
              <h1 className="lg:text-4xl text-xl text-bage">{t("FAQs2")}</h1>
              <h3 className="lg:text-xl text-sm text-white">{t("FAQs per")}</h3>
            </div>
            <img
              src={image}
              alt=""
              className="w-full h-full object-cover rounded-xl"
            />
          </div>

          <div className="w-full mb-[90px]">
            <div className="w-full transition-faq">
              {faqData.map((item, i) => (
                <div
                  key={i}
                  data-aos="fade-left"
                  data-aos-duration="1500"
                  className="mb-[20px] text-[15px] sm:text-lg md:text-xl transition-faq"
                >
                  <div
                    onClick={() => toggle(i)}
                    className={`${
                      selected === i ? "text-white bg-purple" : "text-purple"
                    } transition-faq border-[1px] border-purpleLight rounded-lg py-[25px] px-[35px] cursor-pointer flex items-center justify-between`}
                  >
                    <h2>{lng === "ar" ? item.question_ar : item.question_en}</h2>
                    <span>
                      {selected === i ? (
                        <TiArrowSortedUp />
                      ) : (
                        <TiArrowSortedDown />
                      )}
                    </span>
                  </div>

                  <div
                    className={`transition-all duration-1000 border-b-[2px] border-l-[2px] border-r-[2px] rounded-lg ease-in-out overflow-hidden ${
                      selected === i
                        ? "max-h-[500px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="text-gray py-[25px] px-[35px] bg-white sh">
                      {lng === "ar" ? item.answer_ar : item.answer_en}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Faqs;
