import React, { useEffect, useState } from "react";
import EquipmentCard from "./EquipmentCard";
import { useSelector } from "react-redux";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Equipment = ({ data }) => {
  const lng = useSelector((state) => state.lang.value);

  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(3);

  const lastPostIndex = currentPage * cardsPerPage;
  const firstPostIndex = lastPostIndex - cardsPerPage;

  const currentCards = data.records.slice(firstPostIndex, lastPostIndex);

  const handleNext = () => {
    if (currentPage < Math.ceil(data.records.length / cardsPerPage)) {
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
      }, 300); // adjust time based on animation
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
      }, 300); // adjust time based on animation
    }
  };
  useEffect(() => {
    const updateCardsPerPage = () => {
      const width = window.innerWidth;

      if (width >= 1536) {
        setCardsPerPage(3);
      } else if (width >= 1025) {
        setCardsPerPage(2);
      } else if (width >= 598) {
        setCardsPerPage(1);
      } else {
        setCardsPerPage(1);
      }
    };
    updateCardsPerPage();
    window.addEventListener("resize", updateCardsPerPage);
    return () => window.removeEventListener("resize", updateCardsPerPage);
  }, [currentPage]);
  return (
    <div>
      <h1
        className={`text-title mt-[40px] border-purple rounded-md py-[13px] ${
          lng === "ar" ? "border-r-[6px] pr-[10px]" : "border-l-[6px] pl-[10px]"
        } text-purple font-medium`}
      >
        {lng === "ar" ? data.section_name : data.section_name_en}
      </h1>
      <div
        data-aos="fade-up-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="flex items-center justify-center mt-[50px] flex-wrap gap-[30px]"
      >
        {currentCards.map((item) => (
          <EquipmentCard
            image={item.file_url}
            titleAr={item.title}
            titleEn={item.title_en}
            descAr={item.description}
            descEn={item.description_en}
          />
        ))}
      </div>
      {lng === "ar" ? (
        <div className="flex items-center w-full justify-center mt-[20px] gap-[50px]">
          <div
            onClick={handleNext}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowForwardIos className="text-2xl text-orange" />
          </div>
          <div
            onClick={handlePrev}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowBackIosNew className="text-2xl text-orange" />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center gap-[50px] mt-[20px]">
          <div
            onClick={handlePrev}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowBackIosNew className="text-2xl text-orange" />
          </div>
          <div
            onClick={handleNext}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowForwardIos className="text-2xl text-orange" />
          </div>
        </div>
      )}
      <div className="text-purple font-medium flex items-center justify-center mt-[20px]">
        {currentPage} / {Math.ceil(data.records.length / cardsPerPage)}
      </div>
    </div>
  );
};

export default Equipment;
