import React from "react";
import { useSelector } from "react-redux";

const EquipmentCard = (props) => {
  const lng = useSelector((state) => state.lang.value);
  return (
    <div className="border-[1px] border-gray card-hover hover:border-purpleLight hover:border-2 transition-all duration-200 rounded-lg w-[400px] p-[12px]">
      <div className="w-full h-[200px]">
        <img
          src={props.image}
          alt=""
          className="w-full h-full rounded-md object-cover"
        />
      </div>

      <h1 className=" font-medium mt-[20px]">
        {lng === "ar" ? props.titleAr : props.titleEn}
      </h1>

      <div
        dir={lng === "ar" ? "rtl" : "ltr"}
        className="text-gray font-medium mt-[20px] h-[100px] overflow-hidden"
        dangerouslySetInnerHTML={{
          __html: lng === "ar" ? props.descAr : props.descEn,
        }}
      />
    </div>
  );
};

export default EquipmentCard;
