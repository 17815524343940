import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const ThirdSection = ({ children }) => {
  const { t } = useTranslation();
  const data = [
    {
      title: t("Service implementation steps"),
    },
  ];
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div className="flex flex-col gap-[20px]">
      {data.map((item, i) => (
        <div key={i} className="mb-[20px] transition-faq">
          <div
            onClick={() => toggle(i)}
            className={`${
              selected === i ? "text-white bg-purple" : "text-purple"
            } transition-faq border-[1px] border-purpleLight rounded-lg py-[25px] px-[35px] cursor-pointer flex items-center justify-between`}
          >
            <h2 className="text-xl">{item.title}</h2>
            <span>
              {selected === i ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </span>
          </div>

          {/* Smooth Transition for Children */}
          <div
            className={`transition-all duration-700 ease-in-out overflow-hidden ${
              selected === i
                ? "max-h-[1000px] opacity-100"
                : "max-h-0 opacity-0"
            }`}
          >
            <div className="text-gray py-[25px] px-[35px] flex flex-col gap-[20px] border-b-[1px] border-l-[1px] border-r-[1px] rounded-b-xl border-purpleLight bg-white sh">
              {children}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThirdSection;
