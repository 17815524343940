import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import apiClient from "../../api/baseUrl/axiosConfig";
import { FaPlay, FaPause } from "react-icons/fa";
import Loader from "../../components/Loader";

const BlogDetails = () => {
  const [loading, setLoading] = useState(true);
  const [fetchedBlog, setFetchedBlog] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const lng = useSelector((state) => state.lang.value);
  const { id } = useParams();

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const getBlogById = async () => {
      try {
        setLoading(true); // Start loading
        const foundBlog = await apiClient.get(`/videos/${id}`);
        const blogs = await apiClient.get("/videos");

        if (foundBlog) {
          setFetchedBlog(foundBlog.data);

          // Filter out the current article and sort based on proximity to current article's id
          const nearestArticles = blogs.data
            .filter((blog) => blog.id !== parseInt(id)) // Exclude the current article
            .sort((a, b) => Math.abs(a.id - id) - Math.abs(b.id - id)) // Sort by proximity to current id
            .slice(0, 5); // Take the closest 5

          setRelatedArticles(nearestArticles);
        }
        setLoading(false); // End loading
      } catch (err) {
        setLoading(false); // End loading in case of error
        console.error("Error fetching blog data:", err);
      }
    };

    getBlogById();
    window.scrollTo(0, 0); // Scroll to the top on ID change
  }, [id]); // Only depend on the 'id' parameter

  if (loading) {
    return <Loader />;
  }

  if (!fetchedBlog) {
    return (
      <div>
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <p>No Video found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <div className="mb-24 md:mb-[15%] container mx-auto">
        <div className="container mx-auto flex flex-col gap-6 md:gap-8 lg:gap-10 px-4 md:px-8">
          <h1
            className={`text-purple py-4 border-purple ${
              lng === "en" ? "border-l-4 pl-4" : "border-r-4 pr-4"
            } rounded-md text-xl md:text-2xl font-medium`}
          >
            {lng === "en" ? fetchedBlog.titleEn : fetchedBlog.titleAr}
          </h1>

          <div className="w-full flex flex-col lg:flex-row gap-8 lg:gap-12">
            {/* Blog Content Section */}
            <div className="w-full lg:w-2/3 flex flex-col gap-6">
              <div className="relative">
                {/* Play/Pause Button */}

                <div
                  onClick={handlePlayPause}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-50 center text-white p-[10px] w-[50px] h-[50px] rounded-full bg-purple bg-opacity-50 flex items-center justify-center"
                >
                  {isPlaying ? (
                    <FaPause className="w-full h-full" />
                  ) : (
                    <FaPlay className="w-full h-full" />
                  )}
                </div>

                {/* Video Element */}
                <video
                  className=" rounded-xl max-h-[450px] min-w-full"
                  ref={videoRef}
                  src={fetchedBlog.videoSrc}
                  onClick={handlePlayPause} // Play/Pause on click
                />
              </div>
              <div className="flex flex-col gap-6">
                <h1 className="text-purple text-xl md:text-2xl font-medium">
                  {lng === "en" ? fetchedBlog.descEn : fetchedBlog.descAr}:
                </h1>
                <div className="flex items-start justify-between text-gray">
                  <p>
                    {lng === "ar" ? fetchedBlog.dateAr : fetchedBlog.dateEr}
                  </p>
                </div>
                <div className="text-md md:text-lg leading-7 md:leading-8">
                  <p
                    dir={lng === "ar" ? "rtl" : "ltr"}
                    dangerouslySetInnerHTML={{
                      __html:
                        lng === "en"
                          ? fetchedBlog.contentEn
                          : fetchedBlog.contentAr,
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Related Articles Section */}
            <div className="border-purple rounded-xl border-[1px] w-full lg:w-1/3 flex flex-col gap-6 px-4 py-6">
              <h1 className="text-xl md:text-2xl font-medium text-purple text-center">
                {lng === "en" ? "Related Videos" : "فيدويهات ذات صلة"}
              </h1>
              {relatedArticles.map((blog) => (
                <Link
                  to={`/videos/${blog.id}`}
                  className="flex items-center gap-4"
                  key={blog.id}
                >
                  <div className="w-20 h-20 md:w-28 md:h-28 rounded-lg overflow-hidden">
                    <img
                      src={blog.image}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-1 flex-col gap-2">
                    <h1 className="font-medium text-lg md:text-xl">
                      {lng === "en" ? blog.titleEn : blog.titleAr}
                    </h1>
                    <p className="text-gray">
                      {lng === "en" ? blog.dateEn : blog.dateAr}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
