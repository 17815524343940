import React, { useEffect, useState, useMemo } from "react";
import { getGeneralSection } from "../../api/generalSection/getGeneralSection";
import { getContactFormSectionHome } from "../../api/contactFormSection/getContactFormSection";
import ContactUsSection from "./components/ContactUsSection";
import FirstSection from "./components/FirstSection";
import SecondSection from "./components/SecondSection";
import ThirdSection from "./components/ThirdSection";
import ForthSection from "./components/ForthSection";
import FaqSection from "./components/FaqSection";
import AboutUsSection from "./components/AboutUsSection";
import Loader from "../../components/Loader"; // Extract loader into its own component

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [contactSection, setContactSection] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const [contactSectionData, sectionsData] = await Promise.all([
          getContactFormSectionHome(),
          getGeneralSection("home-page"),
        ]);
        setContactSection(contactSectionData);
        setSections(sectionsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const renderedSections = useMemo(
    () => (
      <>
        <FirstSection firstSectionData={sections[0]} />
        <SecondSection secondSectionData={sections[1]} />
        <ThirdSection thirdSectionData={sections[2]} />
        <ForthSection forthSectionData={sections[3]} />
        <FaqSection faqSectionData={sections[4]} />
        <AboutUsSection servicesSectionData={sections[5]} />
      </>
    ),
    [sections]
  );

  return (
    <div className="overflow-hidden">
      {loading ? (
        <Loader /> // Reuse loader component
      ) : (
        <div className="overflow-hidden flex flex-col gap-[200px]">
          {sections.length > 0 && renderedSections}
          {contactSection.length > 0 && (
            <div>
              <ContactUsSection contactSectionData={contactSection} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePage;
