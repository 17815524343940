import React from "react";
import image from "../images/vioce.jpg";
import { FaRegPlayCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { setId } from "../features/soundSlice";

const VoiceCard = ({ onToggleSlide, titleAr, titleEn, id }) => {
  const lng = useSelector((state) => state.lang.value);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setId(id)); // Set the ID in the Redux state
    onToggleSlide(); // Toggle the slide
  };

  return (
    <div className="w-[334.72px] sh h-[200px] border-[2px] border-purpleLight rounded-xl relative">
      <div className="h-[50%] bg-purple w-full  border-b-[2px] border-purpleLight rounded-xl relative">
        <button
          onClick={handleClick} // Call the combined function
          className="absolute inset-0 flex items-center justify-center"
        >
          <FaRegPlayCircle className="w-[50px] h-[50px] text-purple" />
        </button>
        <img
          src={image}
          className="w-full h-full bg-cover rounded-xl"
          alt="Voice Thumbnail"
        />
      </div>
      <div className="flex items-center h-[50%] px-[20px] gap-[10px]">
        <div className="w-[10px] h-[10px] bg-black rounded-full"></div>
        <h1 className="text-purple font-medium">
          {lng === "en" ? titleEn : titleAr}
        </h1>
      </div>
    </div>
  );
};

export default VoiceCard;
