import React from "react";
import { useSelector } from "react-redux";

const AboutUsCard = (props) => {
  const lng = useSelector((state) => state.lang.value);
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1000"
      data-aos-anchor-placement="top-center"
      class="sh1 cursor-default relative h-full"
    >
      <span class="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-purple-500 rounded-lg"></span>
      <div class="relative flex items-center gap-[20px] h-full p-5 bg-white border-1">
        <div className="flex-1">
          <div class="flex items-center -mt-1">
            <h3 class="my-2 ml-3 text-lg font-bold text-orange">
              {lng === "ar" ? props.titleAr : props.titleEn}
            </h3>
          </div>
          <div
            dir={lng === "ar" ? "rtl" : "ltr"}
            dangerouslySetInnerHTML={{
              __html: lng === "ar" ? props.paragraphAr : props.paragraphEn,
            }}
            class="mb-2 text-gray max-h-[125px] min-h-[125px] overflow-hidden"
          />
        </div>
        <div className="w-[60px] h-[60px] flex items-center justify-center border-[1px] border-orange rounded-lg">
          <img
            src={props.icon}
            alt="icon"
            className=" object-cover bg-cover w-full h-full rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsCard;
