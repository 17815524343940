import { useEffect } from "react";
import "./App.css";
import AppRoutes from "./AppRoutes";
import Header from "./components/Header";
import i18n from "./translation/i18n";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import "preline/preline";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import OverLayVideo from "./components/OverLayVideo";

function App() {
  const lng = useSelector(function (state) {
    return state.lang.value;
  });

  const vid = useSelector(function (state) {
    return state.video;
  });

  useEffect(
    function () {
      window.document.dir = i18n.dir(lng);
    },
    [lng]
  );
  const location = useLocation();
  useEffect(
    function () {
      window.HSStaticMethods.autoInit();
    },
    [location.pathname]
  );

  useEffect(() => {
    AOS.init();
  }, [location]);

  return (
    <div className={`appbg ${lng === "en" ? "font-inter" : "alexandria"}`}>
      {vid.clicked === "yes" ? (
        <OverLayVideo />
      ) : (
        <div className="fixed hidden inset-0 w-0 h-0 duration-500 transition-all"></div>
      )}
      <Header />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
