import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import mapimage from "../../images/maps.webp";
import Card from "./components/Card";
import { LuClock10 } from "react-icons/lu";
import { PiPhoneCallFill } from "react-icons/pi";
import { RiMailSendLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import imagebg from "../../images/bgtransparent.png";
import img2 from "../../images/soundsSignal.png";
import ContactComp from "../../components/ContactComp";
import AOS from "aos";
import apiClient from "../../api/baseUrl/axiosConfig";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [contactData, setContactData] = useState(null);

  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await apiClient.get("/pages/contact-us");
        setContactData(response.data);
      } catch (err) {
        console.error("Error fetching contact data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchContactData();
  }, []);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className=" overflow-hidden">
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  if (!contactData) {
    return (
      <div className="w-screen h-[70vh] flex items-center justify-center">
        <p>Error loading contact data. Please try again later.</p>
      </div>
    );
  }

  const {
    title,
    title_en,
    sections: { contact_us_section },
  } = contactData;

  const contactInfo = contact_us_section[0];

  return (
    <div className="overflow-x-hidden">
      <div className="container mx-auto my-[20px] flex flex-col gap-[40px]">
        <Title title={lng === "ar" ? title : title_en} />

        <div
          className="flex items-center justify-center"
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <div className="relative w-full max-w-[1100px] rounded-xl">
            <div className="absolute top-[10%] sm:top-[40%] sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 z-30 text-center flex flex-col gap-[10px] lg:gap-[30px]">
              <h1 className="lg:text-4xl text-2xl text-bage">
                {lng === "ar" ? contactInfo.title : contactInfo.title_en}
              </h1>
              <h3 className="lg:text-xl text-md text-white">
                {lng === "ar" ? contactInfo.subtitle : contactInfo.subtitle_en}
              </h3>
            </div>
            <img
              src={imagebg}
              alt="background"
              className="absolute w-full h-[200px] sm:h-[210px] lg:h-[463px] bg-cover rounded-xl"
            />
            <img
              src={contactInfo.background_image}
              alt="contact"
              className="w-full h-[200px] sm:h-[210px] lg:h-[463px] bg-cover rounded-xl border-[1px]"
            />
            <div className="w-full relative top-[-30px] sm:top-[-50px] flex-wrap flex items-center justify-center gap-[15px]">
              <Card
                title={t("Working hours")}
                info={
                  lng === "ar"
                    ? contactInfo.working_hours
                    : contactInfo.working_hours_en
                }
                icon={<LuClock10 className="w-full h-full" />}
              />
              <Card
                title={t("Phone number")}
                info={contactInfo.phone_number}
                icon={<PiPhoneCallFill className="w-full h-full" />}
              />
              <Card
                title={t("Email")}
                info={contactInfo.email}
                icon={<RiMailSendLine className="w-full h-full" />}
              />
              <Link
                to={contactInfo.location_url}
                target="_blank"
                className="w-[162px] h-[150px] sm:w-[210px] sm:h-[160px] lg:h-[180px] lg:w-[250px] rounded-xl"
              >
                <img
                  src={mapimage}
                  className="rounded-xl bg-cover w-full h-full object-cover"
                  alt="map"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="relative w-full h-[140px]">
          <img src={img2} alt="signal" className="absolute w-full" />
        </div>

        <div className="mt-[3%] flex flex-col gap-[20px]">
          <h2
            className={`text-title border-purple py-[12px] rounded-md px-[10px] font-semibold text-purple ${
              lng === "ar" ? "border-r-[9px]" : "border-l-[9px]"
            }`}
          >
            {t("Let's stay in touch")}
          </h2>
          <p className="text-gray text-center sm:text-start w-full sm:max-w-[60%]">
            {t("Let's stay in touch per")}
          </p>
        </div>

        <div
          className="mb-[120px]"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos="fade-right"
        >
          <ContactComp />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
