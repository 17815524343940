import React from "react";

const Loader = () => {
  return (
    <div className=" overflow-hidden">
      <div className="w-screen h-[70vh] flex items-center justify-center">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loader;
