import React, { useRef, useState, useEffect } from "react";
import imagear from "../../../images/podcastar.png";
import imageen from "../../../images/podcasten.png";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaPlay, FaPause } from "react-icons/fa";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

import AOS from "aos";
import apiClient from "../../../api/baseUrl/axiosConfig";

const FirstSection = ({ data }) => {
  const section_description = DOMPurify.sanitize(data.section_description);

  const section_description_en = DOMPurify.sanitize(
    data.section_description_en
  );
  const [video, setVideo] = useState("");

  useEffect(() => {
    AOS.init();

    const getRandomVid = async () => {
      try {
        const response = await apiClient.get("/videos");
        setVideo(
          response.data[Math.floor(Math.random() * response.data.length)]
        );
      } catch (err) {}
    };
    getRandomVid();
  }, []);
  const lng = useSelector((state) => state.lang.value);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className=" mt-[60px] flex flex-col lg:flex-row items-center justify-between gap-[50px]">
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="flex-1 flex justify-center items-center lg:justify-start lg:items-start flex-col gap-[40px]"
      >
        {lng === "ar" ? (
          <div
            dir="rtl"
            className="libSectionDesc_ar"
            dangerouslySetInnerHTML={{ __html: section_description }}
          />
        ) : (
          <div
            dir="ltr"
            className="libSectionDesc_en"
            dangerouslySetInnerHTML={{ __html: section_description_en }}
          />
        )}
      </div>
      <div
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="relative w-[70%] lg:w-[40%] rounded-xl"
      >
        <div
          className={`absolute z-20 sh md:bottom-[-30px] video-icon  bottom-[-20px] ${
            lng === "en"
              ? "md:left-[-30px] left-[-20px]"
              : "md:right-[-30px] right-[-20px]"
          }   w-[14%] md:w-[70px] md:h-[70px] p-[7px] rounded-full text-purple bg-white flex items-center justify-center`}
        >
          <AiOutlineYoutube className="w-full h-full" />
        </div>
        <div
          className={`absolute z-20 w-[30%] md:w-[160px] sh rounded-xl ${
            lng === "en"
              ? "right-[-50px] sm:right-[-75px]"
              : "left-[-50px] sm:left-[-75px]"
          } bottom-[35px]`}
        >
          {lng === "ar" ? (
            <img className="rounded-xl sh" src={imagear} alt="" />
          ) : (
            <img className="rounded-xl sh" src={imageen} alt="" />
          )}
        </div>

        <div className="relative max-h-[350px] bg-gray rounded-xl">
          {/* Play/Pause Button */}

          <div
            onClick={handlePlayPause}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-50 center text-white p-[10px] w-[50px] h-[50px] rounded-full bg-purple bg-opacity-50 flex items-center justify-center"
          >
            {isPlaying ? (
              <FaPause className="w-full h-full" />
            ) : (
              <FaPlay className="w-full h-full" />
            )}
          </div>

          {/* Video Element */}
          <video
            className=" rounded-xl max-h-[350px] w-full"
            ref={videoRef}
            src={video.videoSrc}
            onClick={handlePlayPause} // Play/Pause on click
          />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
