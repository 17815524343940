import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaPlay, FaPause } from "react-icons/fa";
import fileImage from "../../../images/pngtree-file-icon-image_2292647-removebg-preview.png";
import audioImage from "../../../images/png-transparent-sound-waves-acoustic-wave-computer-icons-sound-wave-angle-text-logo-thumbnail-removebg-preview.png";

const ImageCard = ({ fileType, src, title, title_en }) => {
  const lng = useSelector((state) => state.lang.value);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [shouldSlideClass, setShouldSlideClass] = useState("");

  const textShouldSlideTrue = () => {
    if (
      title?.length > (lng === "ar" ? 29 : 20) ||
      title_en?.length > (lng === "ar" ? 29 : 20)
    ) {
      setShouldSlideClass(lng === "ar" ? "sliding-text" : "sliding-text-en");
    }
  };

  const textShouldSlideFalse = () => {
    setShouldSlideClass("");
  };

  // Handle play/pause for video
  const handleVideoPlayPause = () => {
    setIsVideoPlaying((prev) => {
      const action = prev ? "pause" : "play";
      videoRef.current[action]();
      return !prev;
    });
  };

  // Handle play/pause for audio
  const handleAudioPlayPause = () => {
    setIsAudioPlaying((prev) => {
      const action = prev ? "pause" : "play";
      audioRef.current[action]();
      return !prev;
    });
  };

  const renderMedia = () => {
    switch (true) {
      case fileType.includes("image"):
        return (
          <img
            className="rounded-t-lg h-[180px] sm:h-[220px] md:h-[240px] lg:h-[260px] w-full object-cover"
            src={src}
            alt={title}
          />
        );
      case fileType.includes("audio"):
        return (
          <div className="h-[180px] sm:h-[220px] md:h-[240px] lg:h-[260px] w-full flex items-center overflow-hidden justify-center bg-purpleLight relative">
            <img src={audioImage} className="absolute w-full" alt="" />
            <audio ref={audioRef} className="w-full" src={src}></audio>
            <div
              onClick={handleAudioPlayPause}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-50 text-white p-[10px] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] rounded-full bg-purple bg-opacity-50 flex items-center justify-center"
            >
              {isAudioPlaying ? (
                <FaPause className="w-full h-full" />
              ) : (
                <FaPlay className="w-full h-full" />
              )}
            </div>
          </div>
        );
      case fileType.includes("video"):
        return (
          <div className="relative w-full max-w-[380px] h-[180px] sm:h-[220px] md:h-[240px] lg:h-[260px] bg-gray rounded-xl">
            <div
              onClick={handleVideoPlayPause}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-50 text-white p-[10px] w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] rounded-full bg-purple bg-opacity-50 flex items-center justify-center"
            >
              {isVideoPlaying ? (
                <FaPause className="w-full h-full" />
              ) : (
                <FaPlay className="w-full h-full" />
              )}
            </div>
            <video
              className="rounded-xl w-full h-full"
              ref={videoRef}
              src={src}
              onClick={handleVideoPlayPause}
              controls
            />
          </div>
        );
      default:
        return (
          <div className="w-full h-[180px] sm:h-[220px] md:h-[240px] lg:h-[260px] py-[10px]">
            <img
              src={fileImage}
              className="w-full h-full object-cover"
              alt="file"
            />
          </div>
        );
    }
  };

  const renderButtonLabel = () => {
    if (fileType.includes("video"))
      return lng === "ar" ? "تشغيل الفيديو" : "Play";
    if (fileType.includes("image"))
      return lng === "ar" ? "عرض الصورة" : "View the image";
    if (fileType.includes("audio"))
      return lng === "ar" ? "تشغيل الصوت" : "Play";
    return lng === "ar" ? "تحميل الملف" : "Download";
  };

  return (
    <div className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg bg-white card-hover border border-gray rounded-lg shadow">
      {renderMedia()}
      <div className="p-3 sm:p-4 md:p-5">
        <h5
          onMouseEnter={textShouldSlideTrue}
          onMouseLeave={textShouldSlideFalse}
          className={`mb-2 sliding-text-container mt-2 h-[33px] text-xl sm:text-2xl font-bold text-gray`}
        >
          <div className={`${shouldSlideClass} w-[340px]`}>
            {lng === "ar" ? title : title_en}
          </div>
        </h5>
        <Link
          target={
            fileType.includes("file") || fileType.includes("image")
              ? "_blank"
              : ""
          }
          to={
            fileType.includes("file") || fileType.includes("image") ? src : ""
          }
          onClick={
            fileType.includes("video")
              ? handleVideoPlayPause
              : fileType.includes("audio")
              ? handleAudioPlayPause
              : undefined
          }
          className="inline-flex mt-2 items-center px-3 py-2 text-sm sm:text-md md:text-lg font-medium text-center text-white bg-purpleLight rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          {renderButtonLabel()}
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default ImageCard;
