import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import BlogCard from "../../components/BlogCard";
import AOS from "aos";
import "aos/dist/aos.css";
import { getGeneralSection } from "../../api/generalSection/getGeneralSection";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader"; // Reusable loader component
import useScrollToTop from "../../hooks/useScrollToTop"; // Reusable scroll-to-top hook

const Blogs = () => {
  const lng = useSelector((state) => state.lang.value);
  const [loading, setLoading] = useState(true);
  const [articlesData, setArticlesData] = useState({ records: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(9);

  useScrollToTop(); // Automatically scrolls to the top when component is mounted

  // Logic for pagination
  const lastPostIndex = currentPage * cardsPerPage;
  const firstPostIndex = lastPostIndex - cardsPerPage;
  const currentCards = articlesData.records.slice(
    firstPostIndex,
    lastPostIndex
  );

  const handleNext = () => {
    if (currentPage < Math.ceil(articlesData.records.length / cardsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const updateCardsPerPage = () => {
      const width = window.innerWidth;

      if (width >= 1536) {
        setCardsPerPage(9);
      } else if (width >= 1025) {
        setCardsPerPage(6);
      } else {
        setCardsPerPage(6);
      }
    };

    updateCardsPerPage();
    window.addEventListener("resize", updateCardsPerPage);
    return () => window.removeEventListener("resize", updateCardsPerPage); // Cleanup
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        AOS.init();
        const response = await getGeneralSection("articles");
        setArticlesData(response[0]);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div className="overflow-hidden">
      {loading ? (
        <Loader /> // Reusable loader component
      ) : (
        <div className="container mx-auto mt-[20px] mb-[140px] flex flex-col gap-[40px]">
          <Title
            title={
              lng === "ar"
                ? articlesData.section_name
                : articlesData.section_name_en
            }
          />
          <div
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
            className="flex items-center justify-center flex-wrap gap-[40px]"
          >
            {currentCards.map((item) => (
              <BlogCard
                key={item.id}
                link={`/articles/${item.id}`}
                dateAr={item.published_at}
                dateEn={item.published_at_en}
                image={item.image_url}
                titleAr={item.title}
                titleEn={item.title_en}
                descAr={item.description}
                descEn={item.description_en}
              />
            ))}
          </div>

          <div className="w-full flex items-center justify-center">
            <div className="text-purple w-[200px] font-medium flex items-center justify-between mt-[20px]">
              <div
                onClick={handlePrev}
                className={`p-4 border-[1px] border-gray cursor-pointer ${
                  currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Prev
              </div>
              <div>
                {currentPage} /{" "}
                {Math.ceil(articlesData.records.length / cardsPerPage)}
              </div>
              <div
                onClick={handleNext}
                className={`p-4 border-[1px] border-gray cursor-pointer ${
                  currentPage >=
                  Math.ceil(articlesData.records.length / cardsPerPage)
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                Next
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
