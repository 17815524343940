import React, { useEffect, useState, useRef } from "react";
import { Slide, Box, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const GlobalSlide = ({ isOpen, onClose, voiceData }) => {
  const id = useSelector((state) => state.sound.value);
  const [data, setData] = useState({});
  const audioRef = useRef(null); // Create a ref for the audio element

  useEffect(() => {
    // Fetch the audio data by ID
    const getSoundById = async () => {
      const selectedSound = await voiceData.find(
        (item) => item.id === parseInt(id)
      );
      setData(selectedSound || {});
    };

    getSoundById();
  }, [voiceData, id]);

  useEffect(() => {
    // Reload the audio when data.src changes
    if (audioRef.current && data?.file_url) {
      audioRef.current.load(); // Reload the audio source, but don't play automatically
    }
  }, [data.file_url]); // Trigger this effect whenever data.src changes

  return (
    <Slide
      direction="bottom"
      in={isOpen}
      style={{
        position: "fixed",
        bottom: 0,
        width: "100vw",
        height: "30vh",
        zIndex: 50,
      }}
    >
      <Box
        p="40px"
        color="white"
        bg="#9367C7"
        rounded="md"
        shadow="md"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        {/* Conditionally render audio only if data.src exists */}
        {data.file_url ? (
          <audio
            ref={audioRef} // Attach the ref to the audio element
            controls
            style={{
              width: "100%",
              maxWidth: "400px",
              marginBottom: "20px",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <source src={data.file_url} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <p>No audio found for this item.</p>
        )}
        <Button onClick={onClose} colorScheme="red" mt="4">
          Close
        </Button>
      </Box>
    </Slide>
  );
};

export default GlobalSlide;
