import React, { useState } from "react";
import Slide from "../../../components/Slide";
import { SwiperSlide } from "swiper/react";
import VoiceCard from "../../../components/VoiceCrad";
import GlobalSlide from "../../../components/GlobalSlide";
import { useSelector } from "react-redux";

const VoiceSection = ({ data }) => {
  const lng = useSelector((state) => state.lang.value);
  const [isSlideOpen, setSlideOpen] = useState(false);
  const handleToggleSlide = () => {
    setSlideOpen(!isSlideOpen);
  };
  return (
    <div dir={lng === "en" ? "ltr" : "rtl"} className="w-full h-[250px]">
      <Slide>
        {data.records.map((item) => (
          <SwiperSlide>
            <VoiceCard
              id={item.id}
              titleAr={item.title}
              titleEn={item.title_en}
              onToggleSlide={handleToggleSlide}
            />
          </SwiperSlide>
        ))}
      </Slide>
      <GlobalSlide
        voiceData={data.records}
        isOpen={isSlideOpen}
        onClose={handleToggleSlide}
      />
    </div>
  );
};

export default VoiceSection;
