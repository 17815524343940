import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader"; // Reusable loader component
import useScrollToTop from "../../hooks/useScrollToTop"; // Reusable scroll-to-top hook
import apiClient from "../../api/baseUrl/axiosConfig";
import Card from "../../components/Card";

const Blogs = () => {
  const lng = useSelector((state) => state.lang.value);
  const [loading, setLoading] = useState(true);
  const [articlesData, setArticlesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(9);

  useScrollToTop(); // Automatically scrolls to the top when component is mounted

  // Logic for pagination
  const lastPostIndex = currentPage * cardsPerPage;
  const firstPostIndex = lastPostIndex - cardsPerPage;
  const currentCards = articlesData.slice(firstPostIndex, lastPostIndex);

  const handleNext = () => {
    if (currentPage < Math.ceil(articlesData.length / cardsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const updateCardsPerPage = () => {
      const width = window.innerWidth;

      if (width >= 1536) {
        setCardsPerPage(9);
      } else if (width >= 1025) {
        setCardsPerPage(6);
      } else {
        setCardsPerPage(6);
      }
    };

    updateCardsPerPage();
    window.addEventListener("resize", updateCardsPerPage);
    return () => window.removeEventListener("resize", updateCardsPerPage); // Cleanup
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        AOS.init();
        const response = await apiClient.get("/videos");
        setArticlesData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div className="overflow-hidden">
      {loading ? (
        <Loader /> // Reusable loader component
      ) : (
        <div className="container mx-auto mt-[20px] mb-[140px] flex flex-col gap-[40px]">
          <Title title={lng === "ar" ? "جميع الفيديوهات" : "All The Videos"} />
          <div
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
            className="flex items-center justify-center flex-wrap gap-[40px]"
          >
            {currentCards.map((item) => (
              <Card
                id={item.id}
                image={item.image}
                link={`/videos/${item.id}`}
                type="video"
                title={lng === "en" ? item.titleEn : item.titleAr}
                button={lng === "en" ? "Watch the video" : "شاهد الفيديو"}
                desc={lng === "en" ? item.descEn : item.descAr}
                VideoSrc={item.videoSrc}
              />
            ))}
          </div>

          <div className="w-full flex items-center justify-center">
            <div className="text-purple w-[200px] font-medium flex items-center justify-between mt-[20px]">
              <div
                onClick={handlePrev}
                className={`p-4 border-[1px] border-gray cursor-pointer ${
                  currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Prev
              </div>
              <div>
                {currentPage} / {Math.ceil(articlesData.length / cardsPerPage)}
              </div>
              <div
                onClick={handleNext}
                className={`p-4 border-[1px] border-gray cursor-pointer ${
                  currentPage >= Math.ceil(articlesData.length / cardsPerPage)
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                Next
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
