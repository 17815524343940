import React, { useEffect, useState } from "react";
import WorkTeamCard from "./WorkTeamCard";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useSelector } from "react-redux";

import AOS from "aos";
import "aos/dist/aos.css";

const WorkTeamSection = ({ data }) => {
  const lng = useSelector((state) => state.lang.value);

  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(4);
  const [transitioning, setTransitioning] = useState(false);

  const lastPostIndex = currentPage * cardsPerPage;
  const firstPostIndex = lastPostIndex - cardsPerPage;

  const currentCards = data.slice(firstPostIndex, lastPostIndex);

  const handleNext = () => {
    if (currentPage < Math.ceil(data.length / cardsPerPage)) {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
        setTransitioning(false);
      }, 300); // adjust time based on animation
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
        setTransitioning(false);
      }, 300); // adjust time based on animation
    }
  };

  useEffect(() => {
    AOS.init();
    const updateCardsPerPage = () => {
      const width = window.innerWidth;

      if (width >= 1536) {
        setCardsPerPage(4);
      } else if (width >= 1025) {
        setCardsPerPage(3);
      } else if (width >= 598) {
        setCardsPerPage(2);
      } else {
        setCardsPerPage(1);
      }
    };
    updateCardsPerPage();
    window.addEventListener("resize", updateCardsPerPage);
    return () => window.removeEventListener("resize", updateCardsPerPage);
  }, [currentPage]);

  return (
    <div
      data-aos="fade-right"
      data-aos-duration="1000"
      className={`transition-opacity duration-300 ease-in-out ${
        transitioning ? "opacity-0" : "opacity-100"
      }`}
    >
      <div
        data-aos="fade-up-right"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="flex items-center justify-center overflow-hidden flex-wrap gap-[55px]"
      >
        {currentCards.map((item, index) => (
          <WorkTeamCard
            key={index}
            image={item.photo_url}
            nameAr={item.name_ar}
            nameEn={item.name_en}
            descAr={item.description_ar}
            descEn={item.description_en}
          />
        ))}
      </div>
      {lng === "ar" ? (
        <div className="flex items-center w-full justify-center mt-[20px] gap-[50px]">
          <div
            onClick={handleNext}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowForwardIos className="text-2xl text-orange" />
          </div>
          <div
            onClick={handlePrev}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowBackIosNew className="text-2xl text-orange" />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center gap-[50px] mt-[20px]">
          <div
            onClick={handlePrev}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowBackIosNew className="text-2xl text-orange" />
          </div>
          <div
            onClick={handleNext}
            className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage"
          >
            <MdArrowForwardIos className="text-2xl text-orange" />
          </div>
        </div>
      )}
      <div className="text-purple font-medium flex items-center justify-center mt-[20px]">
        {currentPage} / {Math.ceil(data.length / cardsPerPage)}
      </div>
    </div>
  );
};

export default WorkTeamSection;
