import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import AOS from "aos";

import Slide from "../../../components/Slide";
import { SwiperSlide } from "swiper/react";
import Card from "../../../components/Card";

const VideosSection = ({ data }) => {
  const lng = useSelector((state) => state.lang.value);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      data-aos="fade-up-right"
      data-aos-easing="linear"
      data-aos-duration="1000"
      className="w-full my-[40px] h-[450px]"
      dir={lng === "en" ? "ltr" : "rtl"}
    >
      <Slide>
        {data.records.map((item) => (
          <SwiperSlide>
            <Card
              id={item.id}
              image={item.image}
              link={`/videos/${item.id}`}
              type="video"
              title={lng === "en" ? item.titleEn : item.titleAr}
              button={lng === "en" ? "Watch the video" : "شاهد الفيديو"}
              desc={lng === "en" ? item.descEn : item.descAr}
              VideoSrc={item.videoSrc}
            />
          </SwiperSlide>
        ))}
      </Slide>
    </div>
  );
};

export default VideosSection;
