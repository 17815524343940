import React from "react";

const Card = (props) => {
  return (
    <div className=" w-[162px] h-[150px] sm:w-[210px] sm:h-[160px] lg:h-[180px] lg:w-[250px] sh rounded-xl bg-white items-center justify-center flex flex-col gap-[20px]">
      <div className="w-[20px] h-[20px] sm:w-[58px] sm:h-[58px] text-purple flex items-center justify-center">
        {props.icon}
      </div>
      <div className="text-purple text-xs sm:text-lg font-semibold">
        {props.title}
      </div>
      <div className="text-gray text-xs sm:text-lg">{props.info}</div>
    </div>
  );
};

export default Card;
