import React, { memo } from "react";
import Title from "../../../components/Title";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useSelector } from "react-redux";

import img1 from "../../../images/VectorOne.png";
import img2 from "../../../images/VectorTow.png";
import img3 from "../../../images/VectorTree.png";
import img4 from "../../../images/VectorFor.png";
import img5 from "../../../images/bg.png";
import img6 from "../../../images/bg-transparent.png";
import DOMPurify from "dompurify";

const SecondSection = memo(({ secondSectionData }) => {
  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();
  const section_descriptionAr = DOMPurify.sanitize(
    secondSectionData.section_description
  );
  const section_descriptionEn = DOMPurify.sanitize(
    secondSectionData.section_description_en
  );

  return (
    <div className="bg-image flex flex-col justify-center items-center">
      <div className="container mx-auto">
        <div className=" flex items-center sm:mt-0 mt-[20px] justify-between">
          <Title
            title={
              lng === "ar"
                ? secondSectionData.section_name
                : secondSectionData.section_name_en
            }
          />
          <Link
            to={"/our-services"}
            className="text-viewAll w-[112px] sm:w-auto underline text-purple"
          >
            {t("View all")}
          </Link>
        </div>
        <div className="mt-[20px] flex flex-col gap-[40px] md:gap-[0px] md:flex-row items-center justify-between">
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            className=" flex-1 justify-center items-center md:justify-start md:items-start flex flex-col gap-[45px]"
          >
            <div
              className={`${
                lng === "ar" ? "secondSectionDescAr" : "secondSectionDescEn"
              } `}
              dir={lng === "ar" ? "rtl" : "ltr"}
              dangerouslySetInnerHTML={{
                __html:
                  lng === "ar" ? section_descriptionAr : section_descriptionEn,
              }}
            />

            {lng === "ar" ? (
              <div className="flex items-center gap-[18px]">
                <div className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage">
                  <MdArrowForwardIos className="text-2xl text-orange" />
                </div>
                <div className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage">
                  <MdArrowBackIosNew className="text-2xl text-orange" />
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center md:justify-start gap-[18px]">
                <div className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage">
                  <MdArrowBackIosNew className="text-2xl text-orange" />
                </div>
                <div className="w-[50px] cursor-pointer h-[50px] rounded-full flex items-center justify-center bg-bage">
                  <MdArrowForwardIos className="text-2xl text-orange" />
                </div>
              </div>
            )}
          </div>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="mx-[35px] img relative w-[250.77px] h-[270.51px] lg:w-[333.77px] lg:h-[358.51px]"
          >
            <img
              src={img1}
              alt=""
              className=" absolute top-0 rotation w-[100%] h-[100%]"
            />
            <img
              src={img2}
              alt=""
              className="absolute top-0 rotation z-10 w-[100%] h-[100%]"
            />
            <img
              src={img3}
              alt=""
              className="absolute top-0 z-10 w-[100%] h-[100%]"
            />
            <img
              src={img4}
              alt=""
              className="absolute top-0 z-10 w-[100%] h-[100%]"
            />
            <img
              src={img5}
              className="absolute top-0 left-0 w-full h-full object-cover"
              alt="image-firstsection"
            />
            <img
              src={img6}
              className="center w-[80%] h-[80%] object-cover"
              alt="image-firstsection"
            />
            <img
              src={secondSectionData.section_logo}
              className="center w-[50%] h-[45%] object-cover"
              alt="image-firstsection"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default SecondSection;
