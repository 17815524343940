import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FirstSection from "./components/FirstSection";
import SecondSection from "./components/SecondSection";
import ForthSection from "./components/ForthSection";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import img from "../../images/Untitled-2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useParams } from "react-router-dom";
import apiClient from "../../api/baseUrl/axiosConfig";

const ServicesPages = () => {
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState({});
  const { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`/services/${id}`);
        setService(response.data);
        setLoading(false);
      } catch (err) {}
    };
    getData();
    window.scrollTo(0, 0);
    AOS.init();
  }, [id]);

  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden">
      {loading ? (
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <div class="loader"></div>
        </div>
      ) : (
        <div className=" relative">
          <div
            className={` absolute z-0 w-[10%] hidden sm:block rotate-[-45deg] top-0 ${
              lng === "ar" ? "left-[10%]" : "right-[10%]"
            }`}
          >
            <img src={img} alt="" className="w-full h-full" />
          </div>
          <div className=" container mx-auto flex flex-col my-[20px] gap-[60px]">
            <h1 className="text-purple text-title">
              {lng === "ar" ? service.title_ar : service.title_en}
            </h1>
            <div>
              <FirstSection data={service} />
            </div>
            <div className="mt-[60px]">
              <SecondSection data={service} />
            </div>
            <Link
              to={"/our-services"}
              className="text-bermuda text-lg font-medium flex items-center gap-[10px]"
            >
              <h1>{t("View all services")}</h1>
              {lng === "en" ? (
                <MdKeyboardDoubleArrowRight className="text-xl arrow-animation" />
              ) : (
                <MdKeyboardDoubleArrowLeft className="text-xl arrow-animation" />
              )}
            </Link>
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
              data-aos-anchor-placement="top-center"
            >
              <ForthSection />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesPages;
