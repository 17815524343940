import React from "react";
import { useSelector } from "react-redux";

const WorkTeamCard = (props) => {
  const lng = useSelector((state) => state.lang.value);
  return (
    <div className="border-[1px] relative rounded-2xl w-[256px] h-[390px] py-[10px] px-[10px]">
      <div className="bg-white border-purpleLight border-[1px] sh absolute w-[280px] h-[94%] rounded-2xl flex flex-col items-center justify-center gap-[20px] p-[25px]">
        <div className="border-[1px] border-purple w-[120px] h-[120px] rounded-full">
          <img
            src={props.image}
            alt=""
            className="w-full h-full rounded-full object-cover"
          />
        </div>
        <div className="text-center">
          <h1 className="text-lg font-medium">
            {lng === "ar" ? props.nameAr : props.nameEn}
          </h1>
        </div>
        <div
          dir="rtl"
          className="text-center text-gray h-[100px] overflow-hidden"
          dangerouslySetInnerHTML={{
            __html: lng === "ar" ? props.descAr : props.descEn,
          }}
        />
      </div>
    </div>
  );
};

export default WorkTeamCard;
