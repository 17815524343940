import React from "react";
import { useSelector } from "react-redux";
// import titleBgAr from "../images/title-bg-ar.png";
// import titleBgEn from "../images/title-bg-en.png";

const Title = (props) => {
  const lng = useSelector((state) => state.lang.value);
  return (
    <>
      {lng === "ar" ? (
        <div className=" relative  xl:w-[20%] lg:w-[30%] sm:w-[40%] w-[80%]">
          <div className="top-[18%] right-[19%]">
            <h1 className="text-title font-semibold text-purple">
              {props.title}
            </h1>
          </div>
          {/* <div className="">
            <img width={120} src={titleBgAr} alt="" />
          </div> */}
        </div>
      ) : (
        <div className=" relative xl:w-[20%] lg:w-[30%] sm:w-[40%] w-[80%]">
          <div className="top-[18%] left-[19%]">
            <h1 className="text-title font-semibold text-purple">
              {props.title}
            </h1>
          </div>
          {/* <div className="">
            <img width={120} src={titleBgEn} alt="" />
          </div> */}
        </div>
      )}
    </>
  );
};

export default Title;
