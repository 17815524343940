import React, { useEffect, useState, useMemo } from "react";
import Title from "../../components/Title";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AOS from "aos";
import apiClient from "../../api/baseUrl/axiosConfig";
import { getGeneralSection } from "../../api/generalSection/getGeneralSection";
import FirstSection from "./components/FirstSection";
import WorkTeamSection from "./components/WorkTeamSection";
import OurClients from "./components/OurClients";
import Slide from "../../components/Slide";
import { SwiperSlide } from "swiper/react";
import Equipment from "./components/Equipment";
import Loader from "../../components/Loader"; // Extract loader

const About = () => {
  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState(null);
  const [aboutSectionsData, setAboutSectionsData] = useState([]);
  const [workTeamData, setWorkTeamData] = useState([]);
  const [ourClientsData, setOurClientsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();

    const fetchData = async () => {
      try {
        const [aboutResponse, sectionsResponse, teamResponse, clientsResponse] =
          await Promise.all([
            apiClient.get("/pages/about-us"),
            getGeneralSection("about-us"),
            apiClient.get("/team-members"),
            apiClient.get("/clients"),
          ]);

        setAboutData(aboutResponse.data);
        setAboutSectionsData(sectionsResponse);
        setWorkTeamData(teamResponse.data);
        setOurClientsData(clientsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();

  const sectionTitle = useMemo(
    () => (
      <h1
        className={`text-title mt-[30px] border-purple rounded-md py-[13px] ${
          lng === "ar" ? "border-r-[6px] pr-[10px]" : "border-l-[6px] pl-[10px]"
        } text-purple font-medium`}
      >
        {t("Work team")}
      </h1>
    ),
    [lng, t]
  );

  return (
    <div className="overflow-hidden">
      {loading ? (
        <Loader />
      ) : (
        <div className="relative">
          <div className="container mx-auto my-[20px] flex flex-col gap-[40px]">
            <Title
              title={lng === "ar" ? aboutData?.title : aboutData?.title_en}
            />
            <FirstSection data={aboutSectionsData[0]} />
            {sectionTitle}
          </div>

          <div className="w-full mt-[50px] py-[30px] h-full purpleLightGradient">
            <div className="container mx-auto">
              <WorkTeamSection data={workTeamData} />
            </div>
          </div>

          <div className="container mt-[100px] mx-auto mb-[30px]">
            <Equipment data={aboutSectionsData[2]} />
          </div>

          <div className="container mt-[100px] mx-auto mb-[160px]">
            <h1
              className={`text-title mt-[40px] border-purple rounded-md py-[13px] ${
                lng === "ar"
                  ? "border-r-[6px] pr-[10px]"
                  : "border-l-[6px] pl-[10px]"
              } text-purple font-medium`}
            >
              {lng === "ar"
                ? aboutSectionsData[3]?.section_name
                : aboutSectionsData[3]?.section_name_en}
            </h1>

            <div
              data-aos="fade-up-right"
              data-aos-easing="linear"
              data-aos-duration="1000"
              className="flex items-center justify-center mt-[50px] h-[450px] flex-wrap gap-[20px]"
            >
              <Slide>
                {ourClientsData?.map((item) => (
                  <SwiperSlide key={item.id}>
                    <OurClients
                      descAr={item.description_ar}
                      descEn={item.description_en}
                      titleAr={item.name_ar}
                      titleEn={item.name_en}
                      image={item.logo_url}
                    />
                  </SwiperSlide>
                ))}
              </Slide>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
