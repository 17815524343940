import React, { memo, useEffect } from "react";
import ContactComp from "../../../components/ContactComp";
import Title from "../../../components/Title";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

const ContactUsSection = memo(({ contactSectionData }) => {
  const lng = useSelector((state) => state.lang.value);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className=" container mx-auto">
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className="mt-[30px] flex flex-col gap-[30px] mb-[150px]"
      >
        <Title
          title={
            lng === "ar"
              ? contactSectionData[0].section_name
              : contactSectionData[0].section_name_en
          }
        />
        <ContactComp />
      </div>
    </div>
  );
});

export default ContactUsSection;
