import React, { useEffect } from "react";
import mic from "../../../images/mice.png";
import piano from "../../../images/piano.png";
import ar from "../../../images/podcastar.png";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

import AOS from "aos";

const FirstSection = ({ data }) => {
  const section_description = DOMPurify.sanitize(data.section_description);

  const section_description_en = DOMPurify.sanitize(
    data.section_description_en
  );
  useEffect(() => {
    AOS.init();
  }, []);
  const lng = useSelector((state) => state.lang.value);
  return (
    <div className="w-full flex flex-col md:flex-row items-center justify-between gap-[40px]">
      <div
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="flex flex-col gap-[40px]"
      >
        {lng === "ar" ? (
          <div
            dir="rtl"
            className="libSectionDesc_ar"
            dangerouslySetInnerHTML={{ __html: section_description }}
          />
        ) : (
          <div
            dir="ltr"
            className="libSectionDesc_en"
            dangerouslySetInnerHTML={{ __html: section_description_en }}
          />
        )}
      </div>
      <div
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className={`w-full relative min-w-[43%] sm:h-[500px] flex-1 ${
          lng === "ar" ? "rounded-l-[20%]" : "rounded-r-[20%]"
        } border-[2px] border-purple p-[14px]`}
      >
        <div
          className={` absolute  w-[10%] p-[9px] h-[20%] ${
            lng === "ar"
              ? "top-[-15px] right-[-15px]"
              : "top-[-15px] left-[-15px]"
          } sm:w-[59.89px] sm:h-[58.74px] sh bg-white rounded-full flex items-center justify-center`}
        >
          <img src={mic} alt="" className="w-full h-full" />
        </div>
        <div
          className={` absolute ${
            lng === "ar"
              ? "bottom-[35px] right-[-15px]"
              : "bottom-[35px] left-[-15px]"
          }   w-[10%] p-[9px] h-[20%] sm:w-[59.89px] sm:h-[58.74px] sh bg-white rounded-full flex items-center justify-center`}
        >
          <img src={piano} alt="" className="w-full h-full" />
        </div>
        <div
          className={` absolute ${
            lng === "ar"
              ? "bottom-[35px] sm:left-[-65px] left-[-15px]"
              : "bottom-[35px] sm:right-[-65px] right-[-15px]"
          }  w-[30%] h-[20%] sm:w-[175px] sm:h-[69px] sh bg-white rounded-lg flex items-center justify-center`}
        >
          <img src={ar} alt="" className="rounded-lg w-full h-full" />
        </div>
        <img
          src={data.section_logo}
          alt=""
          className={`w-full h-full ${
            lng === "ar" ? "rounded-l-[20%]" : "rounded-r-[20%]"
          } object-cover`}
        />
      </div>
    </div>
  );
};

export default FirstSection;
