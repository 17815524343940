import React from "react";
import { useSelector } from "react-redux";

const LibTitle = (props) => {
  const lng = useSelector((state) => state.lang.value);
  return (
    <h1
      className={`text-purple text-title border-purple rounded-md ${
        lng === "ar" ? "pr-[10px] border-r-[6px]" : "pl-[10px] border-l-[6px]"
      }  py-[10px] text-xl`}
    >
      {props.title}
    </h1>
  );
};

export default LibTitle;
