import React, { useState } from "react";
import { FaRegPlayCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import style from "./components.module.css";
import { useDispatch } from "react-redux";
import { setId, setVideoSrc, setVideoTitle } from "../features/videoSlice";
import { setCliked } from "../features/videoSlice";
import { useSelector } from "react-redux";

const Card = (props) => {
  const lng = useSelector((state) => state.lang.value);
  const [shouldSlideClass, setShouldSlideClass] = useState("");

  const textShouldSlideTrue = () => {
    if (
      props.title?.length > (lng === "ar" ? 60 : 40) ||
      props.title_en?.length > (lng === "ar" ? 60 : 40)
    ) {
      setShouldSlideClass(lng === "ar" ? "sliding-text" : "sliding-text-en");
    }
  };

  const textShouldSlideFalse = () => {
    setShouldSlideClass("");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div class="w-[335px] rounded-lg overflow-hidden sh">
      <div className="h-[200px] relative">
        <img
          class="w-full h-full object-cover"
          src={props.image}
          alt="Sunset"
        />
        {props.type === "video" ? (
          <div
            onClick={() => {
              dispatch(setId(props.id));
              dispatch(setVideoSrc(props.VideoSrc));
              dispatch(setVideoTitle(props.title));
              dispatch(setCliked("yes"));
            }}
            className="center cursor-pointer text-white w-[60px] h-[60px]"
          >
            <FaRegPlayCircle className="w-full h-full" />
          </div>
        ) : (
          ""
        )}
      </div>
      <div class="px-6 py-4">
        <div
          onMouseEnter={textShouldSlideTrue}
          onMouseLeave={textShouldSlideFalse}
          className={`${shouldSlideClass} font-bold text-xl mb-2 max-h-[35px] overflow-hidden min-h-[35px]`}
        >
          {props.title}
        </div>
        <p class="text-gray-700 text-base max-h-[95px] min-h-[95px] overflow-hidden">
          {props.desc}
        </p>
      </div>
      <div onClick={() => navigate(props.link)} class={`${style.btnVideo}`}>
        <p className=" relative z-30">{props.button}</p>
      </div>
    </div>
  );
};

export default Card;
