import React from "react";
import { Link } from "react-router-dom";
import image404 from "../../images/404.png";

const NotFoundPage = () => {
  return (
    <div className="h-[70vh] flex items-center justify-center w-full">
      <div className="flex flex-col gap-[20px] items-center">
        <div className="w-[250px]">
          <img
            src={image404}
            alt=""
            className="w-full h-full object-cover bg-cover"
          />
        </div>
        <h1 className="text-gray text-f-desc">
          Sorry, we couldn't find this page. But don't worry, you can find
          plenty of other things on our{" "}
          <Link to={"/"} className="text-bermuda">
            Home Page
          </Link>
        </h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
