import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import AllServicesCard from "./components/AllServicesCard";
import { getGeneralSection } from "../../api/generalSection/getGeneralSection";
import { useSelector } from "react-redux";

const AllServices = () => {
  const lng = useSelector((state) => state.lang.value);
  const [loading, setLoading] = useState(true);
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      try {
        setLoading(true);
        const response = await getGeneralSection("our-services");
        setServicesData(response);
        setLoading(false);
      } catch (err) {}
    };
    getData();
  }, []);

  return (
    <div className=" overflow-hidden">
      {loading ? (
        <div className="w-screen h-[70vh] flex items-center justify-center">
          <div class="loader"></div>
        </div>
      ) : (
        <div>
          <div className="container mx-auto flex flex-col gap-[30px] mt-[30px] mb-[150px]">
            <Title
              title={
                lng === "ar"
                  ? servicesData[0].section_name
                  : servicesData[0].section_name_en
              }
            />
            <div className="grid gr grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {servicesData[0].records.map((item) => (
                <div
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  data-aos="fade-left"
                >
                  <AllServicesCard
                    key={item.id}
                    linkId={item.id}
                    icon={item.icon_url}
                    titleAr={item.title_ar}
                    descAr={item.description_ar}
                    titleEn={item.title_en}
                    descEn={item.description_en}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllServices;
